.modal-dialog {
    max-height: calc(100% - 40px);
}

.modal-dialog.full-height {
    height: calc(100% - 40px);
}

.modal-content {
    background-color: #1c1c1c;
    border: 1px solid #505050;
    height: 100%;
}

.modal-header {
    background-color: #303030;
    border-bottom: 1px solid #505050;
}

.modal-footer {
    border-top: 1px solid #505050;
}

.btn-close {
    color: #EF4119;
}



/* Animate menu */

.animate {
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

@keyframes slideIn {
    0% {
        transform: translateY(0rem);
        opacity: 0;
    }

    100% {
        transform: translateY(50px);
        opacity: 1;
    }

    0% {
        transform: translateY(0rem);
        opacity: 0;
    }
}

.slideIn {
    animation-name: slideIn;
}