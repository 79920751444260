html,
body {
  background-color: #1c1c1c;
  /* color: #EF4119; */
  color: #E0E0E0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Chakra Petch', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #262626;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.btn-theme-red-noborder {
  border: 0px;
  color: #EF4119;
}

.btn-theme-red {
  border: 1px solid #EF4119;
  color: #EF4119;
}

.btn-theme-red:hover {
  color: #EF4119;
}

.btn-theme-red:focus {
  box-shadow: 0 0 5px rgba(244, 81, 30, 1);
}


.dropdown-theme-grey {
  border: 1px solid #505050;
  color: #E0E0E0;
  background-color: #303030;
}

.dropdown-theme-grey a {
  color: #E0E0E0;
  border-bottom: 1px dotted #505050;
}

.dropdown-theme-grey a:last-child {
  border-bottom: 0px;
}

.dropdown-theme-grey a:hover {
  color: #E0E0E0;
  background-color: #505050;
}

.dropdown-theme-grey li {
  border-bottom: 1px dotted #505050;
}

.dropdown-theme-grey li:last-child {
  border-bottom: 0px;
}


.dropdown-theme-red {
  border: 1px solid #EF4119;
  color: #EF4119;
  background-color: #161616;
}

.dropdown-theme-red a {
  color: #EF4119;
  border-bottom: 1px dotted rgba(244, 81, 30, .25);
}

.dropdown-theme-red a:last-child {
  border-bottom: 0px;
}

.dropdown-theme-red a:hover {
  color: #EF4119;
  background-color: rgba(244, 81, 30, .1);
}

.dropdown-theme-red li {
  border-bottom: 1px dotted rgba(244, 81, 30, .25);
}

.dropdown-theme-red li:last-child {
  border-bottom: 0px;
}


.desktop-icon {
  width: 75px;
  height: 75px;
  position: relative;
}

.desktop-icon .title {
  display: block;
  position: absolute;
  left: 0%;
  width: 75px;
  top: 75px;
  font-size: 8pt;
  line-height: 1;
}

.round {
  border-radius: 50%;
}

.menubutton {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -20px;
  top: -5px;
  width: 60px;
  height: 60px;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}

.box-grey {
  border: 1px solid #505050;
  border-radius: 5px;
  background-color: #303030;
  white-space: nowrap;
}

.text-plain {
  text-decoration: none !important;
}

.text-orange {
  color: #EF4119;
}

.text-orange.glow {
  color: #EF4119;
  text-shadow: 0 0 3px #EF4119;
}

.text-grey {
  color: #808080;
}

.text-small {
  font-size: 10pt;
}